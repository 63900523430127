import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import {navigateToParametrized, getQueryParamNumber, getQueryParam} from '../../lib/url';
import ReportsList from './report/ReportsList';
import { ReportDefinitions } from '../../constants/propTypesDefinitions';
import AddReportButton from './report/reportList/AddReportButton';
import MobilePageHeader from '../general/MobilePageHeader';
import {Trans, t} from '@lingui/macro';
import EmployeesSelect from '../project/EmployeesSelect';
import { RIGHTS } from '../../constants/Rights';
import ColumnFilterLayout from '../project/ColumnFilterLayout';
import SearchByLocation from '../general/SearchByLocation';

/**
 * @fero
 */

class ReportsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.REPORTS]: ReportDefinitions.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    onReportSelect = (reportId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.ID_REPORT]: reportId});
    };

    onReportOwnerChange = (ownerId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.REPORT_ID_OWNER]: ownerId});
    };

    render() {
        const {location, [GLOBAL_DATA.REPORTS]: reports, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const selectedReportId = getQueryParamNumber(location, QUERY_PARAMS.ID_REPORT);
        const reportOwnerId = getQueryParamNumber(location, QUERY_PARAMS.REPORT_ID_OWNER);
        return <React.Fragment>
            <Helmet
                title={t`Zoznam reportov`}
            />
            <div className="d-flex flex-column full-size-width full-size-height">
                <div className="flex-item-static">
                    <MobilePageHeader title={<Trans>Zoznam reportov</Trans>}/>
                    <ColumnFilterLayout
                        collapsible={false}
                        filters={[
                            [
                                {
                                    selector: <SearchByLocation
                                        textQueryParamKey={QUERY_PARAMS.REPORT_NAME}
                                    />
                                }
                            ],
                            [
                                {
                                    label: <Trans>Obchodník: </Trans>,
                                    selector: <EmployeesSelect
                                        value={reportOwnerId}
                                        placeholder={<Trans>Všetci</Trans>}
                                        onChange={this.onReportOwnerChange}
                                        allowClear={true}
                                    />,
                                    rightsFrom: RIGHTS.MANAGER
                                }
                            ],
                            [
                                {
                                    selector: <AddReportButton/>,
                                    rightsFrom: RIGHTS.MANAGER
                                }
                            ]
                        ]}
                    />
                </div>
                <div className="overflow-auto flex-item-dynamic-1">
                    <ReportsList
                        reports={reports}
                        selectedReportId={selectedReportId}
                        reloadReports={() => reload([GLOBAL_DATA.REPORTS])}
                        onReportSelect={this.onReportSelect}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.REPORTS, GLOBAL_DATA.RELOAD_DATA])(ReportsPage)
);