import React from 'react';
import {Trans, t} from '@lingui/macro';
import NavigateButton from '../../../general/NavigateButton';
import { ROUTES, QUERY_PARAMS } from '../../../../constants/navigation';
import { Icon } from 'antd';

/**
 * @fero
 */

class AddReportButton extends React.PureComponent {
    render() {
        return <NavigateButton
            to={ROUTES.REPORT_MANAGE}
            type="primary"
            queryParams={{[QUERY_PARAMS.ID_REPORT]: undefined}}
        >
            <Icon type="plus" className="mr-2"/>
            <Trans>Vytvoriť nový report</Trans>
        </NavigateButton>;
    }

}

export default AddReportButton;