import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import NavigateButton from '../../../general/NavigateButton';
import { ROUTES, QUERY_PARAMS } from '../../../../constants/navigation';

/**
 * @fero
 */

class CopyReportButton extends React.PureComponent {
    static propTypes = {
        reportId: PropTypes.number.isRequired,
        size: PropTypes.string,
    };

    render() {
        const {reportId, size, ...props} = this.props;
        return <NavigateButton
            to={ROUTES.REPORT_MANAGE}
            queryParams={{[QUERY_PARAMS.ID_REPORT]: reportId, [QUERY_PARAMS.REPORT_ACTION]: 'copy'}}
            tooltipTitle={<Trans>Kopírovať report</Trans>}
            size={size}
            {...props}
        >
            <Icon type="copy"/>
            { size != 'small' ?
                <span className="ml-2">
                    <Trans>Kopírovať report</Trans>
                </span> :
                null
            }
        </NavigateButton>;
    }

}

export default CopyReportButton;